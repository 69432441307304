import { useState, useEffect } from "react";

const ping = async (domain) => {
  try {
    const response = await fetch(
      `https://www.khoadam.com/domains?domain=${domain}`
    );
    return await response.json();
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default function Index() {
  const domain = window.location.hostname;
  const [data, setData] = useState(null);
  const [price, setPrice] = useState("Negotiable");

  useEffect(() => {
    ping(domain).then((res) => {
      setData(res);
      if (res[domain]) {
        setPrice(res[domain].price);
      }
    });
  }, [domain]);

  return (
    <div style={{ textAlign: "left", marginLeft: "12px" }}>
      <h2>Domain for sale: {domain}</h2>
      <p>Price: {price ? `${price} USD` : "Negotiable"}</p>
      <details>
        <summary>Contact:</summary>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:damanhkhoa@gmail.com"
          >
            dam.anh.khoa@gmail.com
          </a>
        </p>
        <p>
          <i style={{ marginLeft: "12px" }}>0904488805</i>
        </p>
      </details>
      <div style={{ textAlign: "left", marginTop: "12px" }}>
        {data && (
          <details>
            <summary>Other domains for sale:</summary>
            <ul>
              {Object.keys(data).map((key) => (
                <li key={key}>
                  <p>
                    {data[key].domain}:{" "}
                    {data[key].price ? `${data[key].price} USD` : "Negotiable"}
                  </p>
                </li>
              ))}
            </ul>
          </details>
        )}
      </div>
    </div>
  );
}
